<template>
    <content-wrapper
        :title="title"
        class="day-book-list"
    >
        <w-data-table
            :loading="loading"
            :headers="headers"
            :items="collection.data"
            :items-per-page="collection.per_page"
            :footer-props="footerProps"
            :server-items-length="collection.total"
            :page="collection.current_page"
            hide-default-header
            searchable
            @change:options="fetchItems"
        >
            <template #item.course="{ item }">
                <v-list
                    dense
                    class="pa-0"
                >
                    <v-list-item
                        v-if="$auth.isStudent() && item.groups.length === 1"
                        :to="{name: 'daybook.form', query: { g: item.groups[0].uuid, c: item.uuid }}"
                    >
                        <v-list-item-avatar>
                            <w-icon
                                color="secondary"
                                value="DAYBOOK"
                            />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                            <v-list-item-subtitle>
                                {{ item.groups[0].level.title }} ({{ item.groups[0].level.school.title }})
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <w-icon value="NEXT" color="primary"/>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-group v-else>
                        <template v-slot:activator>
                            <div class="day-book-list__activator subtitle-2 font-weight-bold px-5 py-4">
                                {{ item.title }}
                            </div>
                        </template>
                        <v-divider/>
                        <template
                            v-for="(group, index) in item.groups"
                        >
                            <v-list-item
                                :key="group.uuid"
                                :to="{name: 'daybook.form', query: { g: group.uuid, c: item.uuid }}"
                            >
                                <v-list-item-avatar>
                                    <w-icon
                                        color="primary"
                                        value="GROUP"
                                    />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="group.title"></v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ group.level.title }} ({{ group.level.school.title }})
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <w-icon value="NEXT" color="primary"/>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider :key="group.uuid + index"/>
                        </template>
                    </v-list-group>
                </v-list>
            </template>
        </w-data-table>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import { getDayBookCourses } from '@apps/school/api/daybook'

export default {
    name: 'DayBookIndex',
    components: {
        ContentWrapper
    },
    computed: {
        title() {
            return this.$trans('Day book')
        },
        memberType() {
            return this.$auth.isTeacher()
                ? 'teacher'
                : 'group'
        },
        memberUuid() {
            return this.$auth.isStudent()
                ? this.$auth.member.group_uuid
                : this.$auth.member.uuid
        },
        headers() {
            return [
                {
                    text: this.$trans('Course'),
                    value: 'course'
                }
            ]
        }
    },
    data: () => ({
        loading: false,
        footerProps: {
            itemsPerPageOptions: [5, 10, 25, 50, 100, -1]
        },
        collection: {
            per_page: 5
        }
    }),
    methods: {
        fetchItems(payload) {
            this.loading = true

            payload[this.memberType] = this.memberUuid

            getDayBookCourses(payload)
                .then(response => {
                    this.collection = response.data.data
                    this.loading = false
                })
        }
    }
}
</script>

<style lang=scss>
.day-book-list {
    table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
        background: transparent !important;
    }

    table > tbody > tr > td {
        padding: 0 !important;
    }

    table > tbody > tr:not(:last-child) > td:last-child {
        // border-bottom: none !important;
    }

    .v-list-group {
        .v-list-group__header {
            padding: 0;

            .day-book-list__activator {
                flex: 1;
            }

            &__append-icon {
                justify-content: flex-start !important;
            }
        }
    }
}

.theme--light .day-book-list .v-list-group--active {
    .v-list-group__header {
        background-color: #5e56690a;
    }
}

.theme--dark .day-book-list .v-list-group--active {
    .v-list-group__header {
        background-color: #3b355a;
    }
}

</style>
